import React, { useState } from "react";
import ReactSearchBox from "react-search-box";
import {FaSearch} from 'react-icons/fa'

const CustomSearch = ({callbackFn}) => {
    const [value, setValue] = useState('');
    const handleKeyPress = (e,val,isIconClick) => {
        if(e.which === 13 || isIconClick) {
            callbackFn(val);
            e.preventDefault();
        }
    }
    return (
        <form className="form customSearch">
            <div className="inputIcon">
                <FaSearch onClick={e=>handleKeyPress(e,value,true)} />
                <input type='text' className="searchBar" placeholder="Search..." value={value} onChange={e=>setValue(e.target.value)} onKeyPress={e=>handleKeyPress(e,e.target.value)} />
            </div>
        </form>
    //   <ReactSearchBox
    //     placeholder="Search..."
    //     data={data}
    //     callback={(record) => callbackFn(record)}
    //   />
    );
};

export default CustomSearch;