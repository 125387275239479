import React,{useState,useEffect} from 'react';
import { Col, Container, Row, Card,CardBody, Modal,ModalHeader,ModalBody,ModalFooter } from 'reactstrap';
import { Button, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import axios,{post,get,patch} from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import config from '../../../config';
import Loader from '../../Helper/Loader'
import empty from 'is-empty'
import Select from 'react-select'
import Breadcrumbs from '../../Helper/Breadcrumbs'
import MarkDates from './markDates';
import {FormControl, FormControlLabel, RadioGroup,Radio, Dialog, DialogActions,DialogContent, DialogContentText,DialogTitle} from '@mui/material'
import moment from 'moment';
import BulkUpdate from './bulkUpdate';


const UpdateDates = (props) => {
  let history = useHistory();
  let tokenKey = {Authorization:'Token '+sessionStorage.getItem('tokenKey')};
  let propsState = props.history.location.state;
  const [indexVal, setIndexVal] = useState(0);
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [mandap, setMandap] = useState([]);
  const [mandapList, setmandapList] = useState([]);
  const [loading, setloading] = useState(true);
  const [overlay, setoverlay] = useState(false);
  const [showCal, setshowCal] = useState(false);
  const [calData, setcalData] = useState([]);
  const [selDates, setselDates] = useState([]);
  const [showOptions, setshowOptions] = useState(false);
  const [optionVal,setoptionVal] = useState('muhurtham');
  const [bulkData, setBulkDate] = useState(null)
  let breadcrumbData = [
    {
      title : 'Update Muhurtham / Non-Muhurtham Dates',
      href : '#'
    }
  ]
  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    };
  const [tab, setTab] = useState({
      details:true,
      bulk:false
  });
    const [tabData, setTabData] = useState([
        {
            name:'Update Dates',
            id:'details',
            show:true
        },
        {
          name:'Bulk Update',
          id:'bulk',
          show:true
      }
    ]);

    const handleTab = (e,index)=>{
      let tabValue = e.target.id;
      setshowCal(false);
      setMandap(null)
      setIndexVal(index);
      setTab({
        details:false,
        bulk:false,
        [tabValue] : true
      })
  };

    const handleSelect = (keyName,setFn,e) =>{
      setselDates([])
      setshowCal(false)
        setFn(e);
        setForm({
            ...form,
            [keyName] : e  && e.value || null
        })
        if (keyName && !!errors[keyName]) setErrors({
            ...errors,
            [keyName]: null
          })
          if(e){
            get(config.service+'/vendor/muhurtham-calendar/'+e.value+'/',{headers:tokenKey}).then(res=>{
              setcalData(res.data);
              setLoader(false);
              setshowCal(true);
            }).catch(err=>{
              if(err.response.status === 401) window.location.href = '/';
              setLoader(false);
              toast.error('Error in data retrieval', toastAttr)
            })
          } 
          else {
            setshowCal(false); setselDates([]);
          }
    }

    const handleInput = (keyName,value) => {
        setForm({
            ...form,
            [keyName] : value
        })
        if (keyName && !!errors[keyName]) setErrors({
            ...errors,
            [keyName]: null
          })
    }


  const renderListView = () => {
      history.push("/claimBonus")
  }

  const findErrors = () => {
    const {  mandap_id} = form
    const newErrors = {}
    if ( empty(mandap_id) ) newErrors.mandap_id = 'This is a required field';

    return newErrors;
   }


  const updateDetails = () => {
    setshowOptions(false)
    if(empty(form.mandap_id))
      toast.error('Select a mandap to update dates', toastAttr)
    else if(!empty(selDates)){
      setLoader(true);
      let postData = []
      selDates.map(obj=>{
          postData.push({
            is_muhurtham: optionVal === 'muhurtham',
            date : moment(obj).format('YYYY-MM-DD'),
          })
      })
      post(config.service+'/vendor/muhurtham-calendar/create/'+form.mandap_id+'/',{dates:postData},{headers:tokenKey}).then(res=>{
        setshowCal(false);
        setcalData(res.data);
        setshowCal(true);
        setoptionVal('muhurtham')
        setLoader(false);
        toast.success('Updated successfully', toastAttr)
      }).catch(err=>{
        setLoader(false);
        toast.error('Updation failed', toastAttr)
        if(err.response.status === 401) window.location.href = '/'; 
      });
    }
  }

  const setLoader = (val) => {
    setloading(val);setoverlay(val);
  }
  
  const saveData = data => {
    setBulkDate(data)
  }

  const updateBulkData = () => {
    if(empty(bulkData.mandap_list) || empty(bulkData.file))
      toast.error('Select mandap(s) and upload a file to proceed', toastAttr)
    else {
      setLoader(true);
      let postData = new FormData;
      postData.append('mandap_list', bulkData.mandap_list);
      postData.append('file', bulkData.file);
      post(config.service+'/vendor/muhurtham-calendar/bulkcreate/',postData,{headers:tokenKey}).then(res=>{
        setLoader(false);
        toast.success('Updated successfully', toastAttr)
      }).catch(err=>{
        toast.error('Updation failed', toastAttr)
        if(err.response.status === 401) window.location.href = '/'; 
      });
    }
  }

  useEffect(() => {
        let vendorId = JSON.parse(sessionStorage.getItem('permission'))[0].vendor_ids[0];
        get(config.service+'/masters/base/vendor-mandap/?&vendor_id='+vendorId,{headers:tokenKey}).then(res=>{
          let mandap=[];
          res.data.map(obj=>{
            mandap.push({value:obj.id,label:obj.mandap_name})
          })
          setmandapList(mandap)
          setLoader(false);
        }).catch(err=>{
          if(err.response.status === 401) window.location.href = '/'
          setLoader(false);
        })
  }, []);


  return (
  <Container className="vendor">
    <Row>
  <Col md={12} className='contBody'>
    <Card>
      <CardBody className='theme-light'>
        <div className="card__title pageNav">
          <div className="bold-text">
          <Breadcrumbs data={breadcrumbData} />
          </div>
        </div>
        <Row className='formBtnsDiv'>
            <Col md={12}>
                    <Button variant='primary' onClick={()=>{tab.details ? setshowOptions(true) : updateBulkData()}}>Update</Button> 
                    {/* <Button variant='secondary' onClick={e=>{renderListView()}}>Cancel</Button> */}
            </Col>
        </Row>
        <Row className='formDiv'>
            <Col md={12}>
                <nav>
                    <div className='nav nav-tabs' id='nav-tab' role='tablist'>
                        <div className='tabList'>
                            {tabData.map((tab,index)=>{
                                let disCls = tab.disable;
                                return tab.show ? (
                                    <a className={`nav-item nav-link ${indexVal === index ? 'active' : ''}`} 
                                    id={tab.id} name={index} data-toggle='tab' role='tab' aria-controls='nav-details' aria-selected='true'
                                    onClick={e=> handleTab(e,index)}>
                                        {tab.name}
                                    </a>
                                ) : '';
                            })}
                        </div>
                    </div>
                </nav>
                <div className='tabContent'>
                    <Form className="form defaultForm" >
                    <Loader loading={loading} overlay={overlay}>
                        {tab.details ? 
                        <Row><Col md={12}>
                                    <Row>
                                        <Col md={10}>
                                            <Row>
                                                <Col md={2}>
                                                    <label>Mandap Name</label>
                                                </Col>
                                                <Col md={7}>
                                                <Select className={`selectBox ${!! errors.mandap_id  ? 'inValidField' : ''}`}
                                                    value={mandap}
                                                    onChange={e=>handleSelect('mandap_id',setMandap,e)}
                                                    options={mandapList}
                                                    isClearable
                                                />
                                                {errors.mandap_id ? 
                                                  <Form.Control.Feedback type='invalid' className='d-block'>
                                                      { errors.mandap_id }
                                                  </Form.Control.Feedback> : ''}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                        </Col></Row>
                        : ''}
                        {tab.bulk ? <BulkUpdate save={saveData} mandapList={mandapList} /> : ''}

                        {showCal ? <MarkDates mandapId={form.mandap_id} data={calData} saveDates={(data)=>setselDates(data)} /> : ''}
                        {showOptions ? 
                    <Dialog open={showOptions} onClose={()=>setshowOptions(false)} maxWidth='md'>
                    <DialogTitle>Mark it as  </DialogTitle>
                    <DialogContent className='slotCont1'>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={optionVal}
                                onChange={e=>setoptionVal(e.target.value)}
                            >
                                <FormControlLabel value="muhurtham" control={<Radio />} label="Muhurtham" />
                                <FormControlLabel value="non-muhurtham" control={<Radio />} label="Non-Muhurtham" />
                            </RadioGroup>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={updateDetails}>Update</Button>
                    <Button onClick={()=>setshowOptions(false)}>Cancel</Button>
                    </DialogActions>
                </Dialog> : ''}
                    </Loader>
                    </Form>
                </div>
            </Col>
        </Row>
      </CardBody>
    </Card>
  </Col>
  </Row>
  <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
  </Container>
);
};

export default UpdateDates;
