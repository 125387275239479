import React,{useState,useEffect} from 'react';
import { Col, Container, Row, Card,CardBody, Modal,ModalHeader,ModalBody,ModalFooter } from 'reactstrap';
import { Button, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import axios,{post,get,patch} from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import config from '../../../../config';
import empty from 'is-empty'
import {TextField, Dialog, DialogActions,DialogContent,FormLabel, FormControl,FormControlLabel,RadioGroup,Radio,DialogTitle} from '@mui/material'


const Options = (props) => {
  let history = useHistory();
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [openDialog, setopenDialog] = useState(true);
  const [optionVal, setoptionVal] = useState('price') 

  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    };
  const [tab, setTab] = useState({
      details:true,
  });
    const [tabData, setTabData] = useState([
        {
            name:'Details',
            id:'details',
            show:true
        }
    ]);

    const handleInput = (keyName,value) => {
        setForm({
            ...form,
            [keyName] : value
        })
        if (keyName && !!errors[keyName]) setErrors({
            ...errors,
            [keyName]: null
          })
    }

  const handleChange = (e) => {
    setoptionVal(e.target.value);
  }

  const proceedToPage = () => {
    if(optionVal === 'price'){
      history.push({
        pathname : '/pricing',
        state : {
          data : props.data
        }
      })
      props.handleClose();
    } else props.proceedToImages();
  }



  return (
    <>
    <Dialog open={true} onClose={props.handleClose} maxWidth='md'>
        <DialogTitle>Choose an option to proceed</DialogTitle>
        <DialogContent>
        <FormControl>
            {/* <FormLabel id="select-option">Choose an option to proceed</FormLabel> */}
            <RadioGroup
                aria-labelledby="select-option"
                name="controlled-radio-buttons-group"
                value={optionVal}
                onChange={handleChange}
            >
                <FormControlLabel value="price" control={<Radio />} label="Update Pricing" />
                <FormControlLabel value="photos" control={<Radio />} label="Upload Images" />
            </RadioGroup>
            </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={proceedToPage}>Proceed</Button>
          <Button onClick={props.handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

  <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
        </>
);
};


export default Options;
