import React,{useState} from 'react'

const StatusSteps = ({data}) => {
    const frameSteps = data => {
        let totalLevels = 0, status = data.status || 'draft', levelPushed = -1;
        let steps = [
            {
                name : 'Draft',
                active : status === 'draft' || status === undefined  ? true : false
            }
        ]
        switch(status){
            case 'waiting_for_approval': 
            steps = [{
                    name : 'Waiting for Approval',
                    active : true
                }]
            break;
            case 'approved': 
            steps = [{
                name : 'Approved',
                active : true
            }]
        break;
            case 'publish': 
            steps = [{
                name : 'Published',
                active : true
            }]
        break;
            case 'rejected': 
            steps = [{
                name : 'Rejected',
                active : true
            }]
        break;
        case 'enquiry': 
            steps = [{
                name : 'Enquiry',
                active : true
            }]
        break;
        case 'quotation': 
            steps = [{
                name : 'Quotation',
                active : true
            }]
        break;
            case 'booking_confirmed': 
            steps = [{
                name : 'Booking Confirmed',
                active : true
            }]
        break;
            case 'voucher_issued': 
            steps = [{
                name : 'Voucher issued',
                active : true
            }]
        break;
        case 'voucher_redeemed': 
            steps = [{
                name : 'Vocher Redeemed',
                active : true
            }]
        break;
        case 'encashed': 
            steps = [{
                name : 'Encashed',
                active : true
            }]
            break;
        case 'registered': 
            steps = [{
                name : 'Registered',
                active : true
            }]
            break;
        case 'published': 
            steps = [{
                name : 'Published',
                active : true
            }]
            break;
        case 'unpublished': 
        case 'unpublish': 
        steps = [{
                name : 'Unpublished',
                active : true
            }]
            break;
        case 'unregistered': 
            steps = [{
                name : 'Unregistered',
                active : true
            }]
            break;
        case 'blocking_initiated': 
            steps = [{
                name : 'Blocking Initiated',
                active : true
            }]
            break;
        case 'blocked': 
            steps = [{
                name : 'Blocked',
                active : true
            }]
            break;
        case 'cancelled': 
        case 'booking_cancelled': 
            steps = [{
                name : 'Booking Cancelled',
                active : true
            }]
            break;
        case 'not_paid': 
            steps = [{
                name : 'Not paid',
                active : true
            }]
            break;
        case 'advance_paid': 
            steps = [{
                name : 'Advance Paid',
                active : true
            }]
            break;
            case 'partially_paid': 
            steps = [{
                name : 'Partially Paid',
                active : true
            }]
            break;
        case 'fully_paid': 
            steps = [{
                name : 'Fully Paid',
                active : true
            }]
            break;
        case 'payment_cancelled': 
            steps = [{
                name : 'Payment Cancelled',
                active : true
            }]
            break;
        case 'paid': 
            steps = [{
                name : 'Paid',
                active : true
            }]
            break;
            case 'refunded': 
            steps = [{
                name : 'Refunded',
                active : true
            }]
            break;
        }
        // totalLevels = 0; //set total levels based on menu perm
        // switch(status){
        //     case 'pending' : levelPushed = 0; steps.push({name:'Approval Pending', active:true});break;
        //     case 'approved' : 
        //         levelPushed = 1; 
        //         steps.push({name:'Approval Pending', active:false});
        //         if(totalLevels > 1)
        //             for(let i=1;i<=totalLevels-1;i++) steps.push({name:'Level '+i+' Approved',active:false})
        //         steps.push({name:'Approved', active:true});
        //         break;
        //     case 'rejected' : 
        //         levelPushed = 1; 
        //         steps.push({name:'Approval Pending', active:false},{name:'Rejected', active:true});
        //         break;
        // }

        // switch(levelPushed){
        //     case -1 : 
        //         steps.push({name:'Approval Pending', active:false});
        //         if(totalLevels > 1)
        //             for(let i=1;i<=totalLevels-1;i++) steps.push({name:'Level '+i+' Approved',active:false})
        //         else steps.push({name:'Approved', active:false});
        //         break;
        //     case 0 : 
        //         if(totalLevels > 1)
        //             for(let i=1;i<=totalLevels-1;i++) steps.push({name:'Level '+i+' Approved',active:false})
        //         else steps.push({name:'Approved', active:false});
        //         break;
        // }
        return steps;
        
    }
    let steps = frameSteps(data);
    return (
        <div className='formWizardSteps'>
            {steps && steps.length >0 && steps.map(obj=>{
                return <div className={`wizardStep ${obj.active ? 'active' : ''}`}>{obj.name}</div>
            })}
        </div>
    );
};
export default StatusSteps;