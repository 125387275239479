import React,{useState,useEffect} from 'react';
import { Col, Container, Row, Card,CardBody } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import Loader from '../../../Helper/Loader'
import {FaRegCalendarAlt,FaQuestionCircle,FaNewspaper,FaRegCalendarCheck,FaMoneyBillWave,FaGift,FaReceipt,FaRegCalendarTimes} from 'react-icons/fa'


const Booking = (props) => {
    let history = useHistory();
    const [data, setData] = useState(props.data.bookings || {});
    const  routeToPage = (name) =>{
        history.push({
            pathname:"/booking",
            state : {filterVal : name}
        })
    }

    return(
        <Row className="dbBooking">
          <Loader loading={props.loading}>
          <Row>
            <Col md={12}>
                <Row className='pad-20'>
                    <Col md={4} className='statusDiv'>
                        <div className='contDiv bg-gradient-1' onClick={e=>routeToPage(null)}>
                            <span className='bookingTitle'>All bookings</span>
                            <span className='circle bg1'>
                                <FaRegCalendarAlt color='aliceblue' />
                            </span>
                            <div className='bookingCount'>{data.total || 0}</div>
                        </div>
                    </Col>
                    {/* <Col md={4} className='statusDiv'>
                        <div className='contDiv bg-gradient-9'  onClick={e=>routeToPage('enquiry')}>
                            <span className='bookingTitle'>Enquiry</span>
                            <span className='circle  bg9'>
                                <FaQuestionCircle color='white' />
                            </span>
                        <div className='bookingCount'>{data.enquiry || 0}</div>
                        </div>
                    </Col> */}
                    <Col md={4} className='statusDiv'>
                        <div className='contDiv bg-gradient-3' onClick={e=>routeToPage('quotation')}>
                            <span className='bookingTitle'>Quotation</span>
                            <span className='circle bg3'>
                                <FaNewspaper color='white' />
                            </span>
                            <div className='bookingCount'>{data.quotation || 0}</div>
                        </div>
                    </Col>
                    <Col md={4} className='statusDiv'>
                        <div className='contDiv bg-gradient-8' onClick={e=>routeToPage('booking_confirmed')}>
                            <span className='bookingTitle'>Confirmed Booking</span>
                            <span className='circle bg8'>
                                <FaRegCalendarCheck color='white' />
                            </span>
                            <div className='bookingCount'>{data.booking_confirmed || 0}</div>
                        </div>
                    </Col>
                </Row>
                <Row className='pad-20'>
                    <Col md={4} className='statusDiv'>
                        <div className='contDiv bg-gradient-4' onClick={e=>routeToPage('voucher_issued')}>
                            <span className='bookingTitle'>Voucher Issued</span>
                            <span className='circle bg4'>
                                <FaReceipt color='white' />
                            </span>
                            <div className='bookingCount'>{data.voucher_issued || 0}</div>
                        </div>
                    </Col>
                    <Col md={4} className='statusDiv'>
                        <div className='contDiv bg-gradient-7' onClick={e=>routeToPage('voucher_redeemed')}>
                            <span className='bookingTitle'>Voucher Redeemed</span>
                            <span className='circle bg7'>
                                <FaGift color='white' />
                            </span>
                            <div className='bookingCount'>{data.voucher_redeemed || 0}</div>
                        </div>
                    </Col>
                    {/* <Col md={4} className='statusDiv'>
                        <div className='contDiv bg-gradient-6'  onClick={e=>routeToPage('encashed')}>
                            <span className='bookingTitle'>Encashed</span>
                            <span className='circle  bg6'>
                                <FaMoneyBillWave color='white' />
                            </span>
                            <div className='bookingCount'>{data.encashed || 0}</div>
                        </div>
                    </Col> */}
                    <Col md={4} className='statusDiv'>
                        <div className='contDiv bg-gradient-5' onClick={e=>routeToPage('cancelled')}>
                            <span className='bookingTitle'>Cancelled</span>
                            <span className='circle bg5'>
                                <FaRegCalendarTimes color='white' />
                            </span>
                            <div className='bookingCount'>{data.cancelled || 0}</div>
                        </div>
                    </Col>
                </Row>
            </Col>
            </Row>
            </Loader>
        </Row>
    );
};

export default Booking;