import React,{useState,useEffect} from 'react';
import { Col, Container, Row, Card,CardBody } from 'reactstrap';
import { get } from 'axios';
import { useHistory } from 'react-router-dom';
import Loader from '../../../Helper/Loader'
import {FaRupeeSign,FaRegCalendarAlt,FaMoneyBillWave,FaRegMoneyBillAlt,FaRegThumbsUp,FaRegBookmark,FaCoins,FaReceipt,FaMoneyCheck} from 'react-icons/fa'
import config from '../../../../config'

// import Grid from "@material-ui/core/Grid";
// import DateFnsUtils from "@date-io/date-fns";
// import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import PinField from "react-pin-field"

const Status = (props) => {
    let history = useHistory();
  let tokenKey = {headers:{Authorization:'Token '+sessionStorage.getItem('tokenKey')}};
    const [data, setData] = useState({});
    const [loading, setloading] = useState(props.loading || true);

    let vendorId = JSON.parse(sessionStorage.getItem('permission'))[0].vendor_ids[0];

    useEffect(() => {
        let month = props.date.getMonth()+1, year = props.date.getFullYear();
        get(config.service+'/vendor/dashboard-data/?vendor_id='+vendorId+'&month='+month+'&year='+year,tokenKey).then(res=>{
            setData(res.data);
            setloading(false);
          }).catch(err=>{
            if(err.response.status === 401) window.location.href = '/'
          })
    }, [props.date]);

    return(
        <Row className="dbStatus">
            <Col md={12}>
          <Loader loading={loading}>
            {/* <Row>
                <Col md={10}></Col>
            <Col md={2}>
                <Form.Label className='monthLabel'>Select a Month</Form.Label>
            </Col> 
                <Col md={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                        <DatePicker
                        variant="inline"
                        openTo="year"
                        // label='Select a Month'
                        views={["year", "month"]}
                        value={month}
                        onChange={newVal => setMonth(newVal)}
                        />                                  
                        </Grid>
                    </MuiPickersUtilsProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack spacing={2}>
                        <DatePicker
                        views={['year', 'month']}
                        label="Select a Month"
                        minDate={new Date('2020-01-01')}
                        maxDate={new Date()}
                        value={month}
                        onChange={(newValue) => {
                            setMonth(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} helperText={null} />}
                        />
                        </Stack>
                    </LocalizationProvider>
                </Col>
            </Row> */}
            <Row>
            <Col md={12}>
                <Row className='pad-20'>
                    <Col md={4} className='statusDiv'>
                        <div className='contDiv bg-gradient-1'>
                            <span className='title'>Total bookings</span>
                            <span className='circle bg1'>
                                <FaRegCalendarAlt color='aliceblue' />
                            </span>
                            <div className='firstDiv'>No.of Bookings : {data.no_of_bookings}</div>
                            <div>No.of days : {data.no_of_days_booked}</div>
                        </div>
                    </Col>
                    <Col md={4} className='statusDiv'>
                        <div className='contDiv bg-gradient-2'>
                            <span className='title'>Total Payment by Customer</span>
                            <span className='circle  bg2'>
                                <FaMoneyBillWave color='white' />
                            </span>
                            <div><FaRupeeSign color='#595959'/><span>{data.total_payment_received && data.total_payment_received.toFixed(2)}</span></div>
                            {/* <div className='amount-pinfield'><PinField className="pin-field" disabled length={7} /></div> */}
                        </div>
                    </Col>
                    <Col md={4} className='statusDiv'>
                        <div className='contDiv bg-gradient-3'>
                            <span className='title'>Cashback earned</span>
                            <span className='circle bg3'>
                                <FaRegMoneyBillAlt color='white' />
                            </span>
                            <div><FaRupeeSign color='#595959'/><span>{data.total_cashbacks && data.total_cashbacks.toFixed(2)}</span></div>
                        </div>
                    </Col>
                </Row>
                <Row className='pad-20'>
                <Col md={4} className='statusDiv'>
                        <div className='contDiv bg-gradient-4'>
                            <span className='title'>Gateway charges saved</span>
                            <span className='circle bg4'>
                                <FaRegThumbsUp color='white' />
                            </span>
                            <div><FaRupeeSign color='#595959'/><span>{data.total_payments_charges && data.total_payments_charges.toFixed(2)}</span></div>
                        </div>
                    </Col>
                    <Col md={4} className='statusDiv'>
                        <div className='contDiv bg-gradient-5'>
                            <span className='title'>Vendor Ranking</span>
                            <span className='circle bg5'>
                                <FaRegBookmark color='white' />
                            </span>
                            <div className=''>Rank : 3</div>
                        </div>
                    </Col>
                    <Col md={4} className='statusDiv'>
                        <div className='contDiv bg-gradient-6'>
                            <span className='title'>Bonus claimed</span>
                            <span className='circle bg6'>
                                <FaCoins color='white' />
                            </span>
                            <div><FaRupeeSign color='#595959'/><span>{data.total_bonus && data.total_bonus.toFixed(2)}</span></div>
                        </div>
                    </Col>
                    
                </Row>
                <Row className='pad-20'>
                    <Col md={4} className='statusDiv'>
                        <div className='contDiv bg-gradient-7'>
                            <span className='title'>No. of vouchers redeemed</span>
                            <span className='circle  bg7'>
                                <FaReceipt color='white' />
                            </span>
                            <div className=''>{data.no_of_vouchers_redeemed || 0}</div>
                        </div>
                    </Col>
                    <Col md={4} className='statusDiv'>
                        <div className='contDiv bg-gradient-8'>
                            <span className='title'>Total Savings</span>
                            <span className='circle bg8'>
                                <FaMoneyCheck color='white' />
                            </span>
                            <div><FaRupeeSign color='#595959'/><span>{data.total_savings_from_BWG && data.total_savings_from_BWG.toFixed(2)}</span></div>
                        </div>
                    </Col>
                </Row>
            </Col>  
            </Row>
            </Loader>
            </Col>
        </Row>
    );
};

export default Status;