import React, {useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import { NavLink,useHistory } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { Button } from 'react-bootstrap';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import empty from 'is-empty'
import {post,get} from 'axios';
import PasswordField from '@/shared/components/form/Password';
import { ToastContainer, toast } from 'react-toastify';
import config from '../../../config'
import ResetPassword from '../components/ResetPasswordForm';



const LogInForm = () =>{
  const [username,setUsername] = useState('');
  const [password,setPassword] = useState('');
  const [showResetPwd, setshowResetPwd] = useState(false);

  let history = useHistory();
  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    };

  const onLoginSubmit = () => {
    let data={
      username : username,
      password:password
    };
    if(empty(username) || empty(password)) 
    toast.error('Enter username and password', toastAttr);
    else
    post(config.service+'/rest-auth/login/',data).then(res=>{
      sessionStorage.setItem('tokenKey',res.data.key)
      sessionStorage.setItem('userName',username)
      get(config.service+'/accounts/user_role/',{headers:{Authorization:'Token '+res.data.key}}).then(res=>{
        sessionStorage.setItem('permission',JSON.stringify(res.data))
        if(sessionStorage.getItem('redirectURL')) history.push(sessionStorage.getItem('redirectURL'))
        else history.push('/dashboard')
      })
    }).catch(err=>{
      toast.error('Login Failed', toastAttr);
    })
  }

  const renderSignUp = (isOTP) => {
    if(isOTP) history.push('/OTPSignIn')
    else history.push('/signup')
  }

  // window.onunload = function () {
  //   sessionStorage.removeItem('tokenKey');
  // }

  useEffect(()=>{
    let param = sessionStorage.getItem('redirectURL');
    sessionStorage.clear();
    if(param) sessionStorage.setItem('redirectURL',param)
  },[])

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onLoginSubmit();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [username,password]);
  
  return (
    <form className="form loginForm" >
        <div className="form__form-group">
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <input type='text' name="name" value={username} onChange={e=> setUsername(e.target.value)}
              placeholder="Name"
            />
          </div>
        </div>
        <div className="form__form-group">
          {/* <span className="form__form-group-label">Password</span> */}
          <div className="form__form-group-field">
          <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <input type='password' name="password" value={password} onChange={e=> setPassword(e.target.value)}
              placeholder="Password"
            />
            {/* <Field
              name="password"
              component={PasswordField}
              placeholder="Password"
              className="input-without-border-radius"
              keyIcon
            /> */}
            {/* <div className="account__forgot-password">
              <NavLink to="/reset_password">Forgot password?</NavLink>
            </div> */}
          </div>
        </div>
        <div className="loginBtns">
        {/* <Button variant="primary" onClick={e=>renderSignUp(true)}>Sign In with OTP</Button> */}
          <Button variant="primary" onClick={e=>onLoginSubmit()}>Sign In</Button>
        <Button variant="primary" onClick={e=>renderSignUp()}>Sign Up</Button>

        </div>
        <div className="loginBtns">
        {/* <Button variant="primary" onClick={e=>renderSignUp()}>Sign Up</Button> */}
          <a className='forgotPwd' onClick={e=>setshowResetPwd(true)}>Forgot Password</a>
        </div>

        {showResetPwd ? <ResetPassword username={username} handleClose={e=>{setshowResetPwd(false)}} /> : ''}

        <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
      </form>
  // <Form onSubmit={onLoginSubmit}>
  //   {({ handleSubmit }) => (
      
  //   )}
  // </Form>
);
};

LogInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
};

export default LogInForm;
