import React from "react";

import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

function ConfirmationModel({
  operation,
  handleConfirmation,
  val,
  modelName,
  onclose,
}) {
  return (
    <div>
      <Dialog
        open={val}
        onClose={onclose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title">
    </DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div
              style={{ color: "black", margin: "10px 0px" }}
            >{`Would you like to confirm  to ${operation} ${modelName} before proceeding? `}</div>

            {`Dear User, before final submission, we kindly request your confirmation to ${operation} ${modelName}. This prevents inadvertent submissions. Thank you for your cooperation.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onclose} color="error">
            Cancel
          </Button>
          <Button onClick={handleConfirmation} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ConfirmationModel.propTypes = {
  operation: PropTypes.string.isRequired,
  handleConfirmation: PropTypes.func.isRequired,
  modelName: PropTypes.string.isRequired,
  close: PropTypes.any.isRequired,
  val: PropTypes.any.isRequired,
};

export default ConfirmationModel;
