import React, {useState,useEffect} from 'react';
import { Container,Col,Row,Card,CardBody } from "reactstrap";
import DateFnsUtils from "@date-io/date-fns";
import {DatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import {Paper, Grid} from "@material-ui/core";
import {createMuiTheme} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/styles";
import {makeStyles} from "@material-ui/core/styles";
import {TextField, Dialog, DialogActions,DialogContent, DialogContentText,DialogTitle} from '@mui/material'
import axios,{post,get,patch} from 'axios';
import Loader from '../../Helper/Loader'
import { Button,Form } from "react-bootstrap";
import config from '../../../config';
import moment from 'moment';
import {BiRupee} from 'react-icons/bi';
import {FaTimes} from 'react-icons/fa'

const materialTheme = createMuiTheme({
    overrides: {
        MuiPickersToolbar: {
            toolbar: {backgroundColor: "#8bc34a",},
        },
        MuiPickersCalendarHeader: {
            switchHeader: {
                backgroundColor: "white",
                color: "#1b5e20",
                fontWeight : 'bold'
        },},},});

// export const styles = makeStyles(() => ({ //define CSS for different date types
//     todayPaper: {
//         width: "60px",
//         height: "60px",
//         backgroundColor: "lightGreen",
//         margin: "3px",
//         boxShadow: "none",
//         borderRadius: 0,
//         padding: "1px",
//         cursor: "pointer",
//         color: " white",
//     },}));
const SpecialPrice = (props) => {
    let tokenKey = {headers:{Authorization:'Token '+sessionStorage.getItem('tokenKey')}};
    const [selDate, setselDate] = useState(new Date());
    const [selDatePrice, setselDatePrice] = useState(null)
    // const classes = styles(); // import those CSS
    const today = new Date(); // just Date object of today
    const sunnyDays = [1, 6, 10, 24, 15] // array of sunny days 1st,6th etc
    const [showDialog, setShowDialog] = useState(false);
    const [data,setData] =useState([])
    const [loading, setloading] = useState(true);
    const [overlay, setoverlay] = useState(false);

    const setLoader = (val) => {
        setloading(val);setoverlay(val);
      }

    useEffect(() => {
        setData([{
            date : '2022-08-30',
            price : 30000
        },{
            date : '2022-09-15',
            price : 40000
        },{
            date : '2022-09-20',
            price : 45000
        },{
            date : '2022-09-25',
            price : 50000
        },])
        get(config.service+'/masters/mandapam/special-rates/',tokenKey).then(res=>{
            setData(res.data)
            setLoader(false); 
        }).catch(err=>{
            setLoader(false); 
            if(err.response.status === 401) window.location.href = '/'
        })
    }, []);

    const getDayElement = (day, selectedDate, isInCurrentMonth, dayComponent) => {
        //generate boolean 
        // const isSunny = sunnyDays.includes(day.getDate()); 
        let hasPrice = false, priceValue = null;
        // data.map(obj=>{
        //     let currDate = new Date(obj.date);
        //     currDate.setHours(0,0,0,0)
        //     if(currDate === day){ hasPrice = true; priceValue = obj.price}
        // })
        for(let i=0;i<data.length;i++){
            let currDate = new Date(data[i].date);
            currDate.setHours(0,0,0,0)
            if(currDate.toDateString() === day.toDateString()){ 
                hasPrice = true; 
                priceValue = data[i].price;
                break;
            }
        }
        const isSelected = day.getDate() === selectedDate.getDate();
        const isToday = day.getDate() === today.getDate() && day.getMonth() === today.getMonth();

        let dateTile
        if (isInCurrentMonth) { //conditionally return appropriate Element of date tile.
            if (hasPrice) {
                dateTile = (
                    <Paper className={`${isSelected ? 'selectedDayPaper' : isToday ? 'todayPaper' : 'normalDayPaper'}`}>
                        <Grid item className='date'>
                            {day.getDate()}
                        </Grid>
                        <Grid item className='price'><BiRupee />{priceValue}</Grid>
                    </Paper>)
            } else {
                dateTile = (
                  <Paper className={`${isSelected ? 'selectedDayPaper' : isToday ? 'todayPaper' : 'normalDayPaper'}`}>   
                    <Grid item  className='date'> {day.getDate()}</Grid>
                    <Grid item><br/></Grid>
                </Paper>)
            }

        } else {
            dateTile = (<Paper className='notInThisMonthDayPaper'>
                <Grid item><br/></Grid>
                <Grid item style={{color: "lightGrey"}}>
                    {day.getDate()}
                </Grid>
            </Paper>)
        }
        return dateTile
    }

    const handleDateSelection = (newDate) => {
        setselDate(newDate);
        let priceValue = null;
        for(let i=0;i<data.length;i++){
            let currDate = new Date(data[i].date);
            currDate.setHours(0,0,0,0)
            if(currDate.toDateString() === newDate.toDateString()){ 
                priceValue = data[i].price;
                break;
            }
        }
        setselDatePrice(priceValue)
        setShowDialog(true)
    }

    const updatePrice = () => {
        let temp = data.filter(val => new Date(val.date).toDateString() !== selDate.toDateString());
        temp.push({
            date : moment(selDate).format('YYYY-MM-DD'),
            price : selDatePrice
        })
        setData(temp);
        setShowDialog(false)
    }

    return (
        <Loader loading={loading} overlay={overlay}>
            <Row>
                <Col md={12}>
                    {/* <div>Select a date to update the price</div> */}
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <div className='displayCalendar'>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <ThemeProvider theme={materialTheme}>
                                <DatePicker
                                    value={selDate}
                                    onChange={newValue=>handleDateSelection(newValue)}
                                    variant="static"
                                    renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => getDayElement(day, selectedDate, isInCurrentMonth, dayComponent)}
                                />

                            </ThemeProvider>
                        </MuiPickersUtilsProvider>
                        <Dialog open={showDialog} onClose={()=>setShowDialog(false)} maxWidth='lg'>
                            <DialogTitle>Enter the Special Price <FaTimes onClick={()=>setShowDialog(false)} className='popup-close' title='close' /></DialogTitle>
                            <DialogContent>
                            <TextField value={selDatePrice} onChange={e=>setselDatePrice(e.target.value)}
                                margin="dense" 
                                id="price"
                                label="Price"
                                type="number"
                                fullWidth
                                variant="standard"
                            />
                            </DialogContent>
                            <DialogActions>
                            <Button onClick={updatePrice}>Update</Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </Col>
            </Row>
        </Loader>
    );}

export default SpecialPrice;